import {JbPageHeaderComponent} from "../../core/component/jb-page-header.component";
import {Button, Col, Descriptions, Divider, Empty, Form, Input, Row, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useJbHttpTjService} from "../../../../core/service/jb-http-tj.service";
import {useEffect, useState} from "react";
import {DescribeSearchComponent} from "./component/describe-search.component";
import {useProcessCodeUtil} from "../../../../core/util/process-code.util";
import {MaskedInput} from "antd-mask-input";
import {CourtDropdown} from "../../core/dropdown/court.dropdown";
import {DatajudProcessEntity} from "../../../../domain/entity/datajud-process.entity";

export function SearchProcessModule() {
  const [data, setData] = useState<DatajudProcessEntity>();
  const [form] = Form.useForm();
  const [processCode, setProcessCode] = useState<string>();
  const [processNumber, setProcessNumber] = useState<string>();
  const [segment, setSegment] = useState<string>();
  const [processYear, setProcessYear] = useState<string>();
  const {getCourtByProcessCode, decode} = useProcessCodeUtil();
  const {search, loading} = useJbHttpTjService();
  
  useEffect(() => {
  }, []);
  
  // 'tjmg', '50055501120178130145'
  function searchData(sg: string, processNumber: string) {
    search(sg, processNumber).then(res => {
      if (res) setData(res)
      else setData(undefined);
    })
  }
  
  useEffect(() => {
    if (processCode) {
      const courtCode = getCourtByProcessCode(processCode);
      if (courtCode) form.setFieldValue('sg', courtCode.toLowerCase());
      const { processNumber, processYear, segment, segmentCode } = decode(processCode);
      setProcessNumber(processNumber)
      setSegment(`${segmentCode} ${segment}`)
      setProcessYear(processYear)
    } else {
      setProcessNumber(undefined)
      setSegment(undefined)
      setProcessYear(undefined)
    }
  }, [processCode]);
  
  function onSubmit({ sg, processNumber }: any) {
    searchData(sg, processNumber);
  }
  
  return (
    <>
      <JbPageHeaderComponent title={'Consula de Processos no CNJ'} />
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onSubmit}
      >
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              label={'Número do Processo'}
              name={'processNumber'}
              rules={[{ required: true }]}
            >
              <MaskedInput
                onBlur={(evt) => {
                  setProcessCode(evt.target.value);
                }}
                mask={'0000000-00.0000.0.00.0000'}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <CourtDropdown name={'sg'} />
          </Col>
        </Row>
        {
          processCode && (
            <Row>
              <Col span={24}>
                <Descriptions layout={'vertical'} bordered>
                  <Descriptions.Item label={'Número do Processo'}>
                    {processNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Ano do Processo'}>
                    {processYear}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Segmento'}>
                    {segment}
                  </Descriptions.Item>
                </Descriptions>
                <br/>
              </Col>
            </Row>
          )
        }
        <Row justify={'end'}>
          <Col>
            <Button
              htmlType={'submit'}
              type={'ghost'}
              icon={<SearchOutlined />}
              shape={'round'}
              loading={loading}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Form>
      {
        !!data ? (
          <>
            <Divider/>
            <DescribeSearchComponent data={data} />
          </>
        ) : (
          typeof data === 'undefined' ? (
            <Empty description={'Nenhum dado econtrado'} />
          ) : null
        )
      }
    </>
  )
}

