import {useJbHttpAuthService} from "./jb-http-auth.service";
import {DatajudProcessEntity} from "../../domain/entity/datajud-process.entity";
import {BaseResponse} from "../common/base-response.common";

export function useJbHttpTjService() {
  const { get, loading } = useJbHttpAuthService()
  
  function search(sg: string, processNumber: string): Promise<DatajudProcessEntity | null> {
    const parsedProcessNumber = processNumber.split('.').join('').split('-').join('');
    
    return get(
      `datajud?sg=${sg}&processNumber=${parsedProcessNumber}`
    )
  }
  
  function findDatajudProcess(processId: string): Promise<BaseResponse<any>> {
    return get(
      `datajud/process/${processId}`
    )
  }
  
  return {
    search,
    loading,
    findDatajudProcess
  }
}