import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useCallback, useEffect, useState} from "react";
import {PawnEntity} from "../../../../../../domain/entity/pawn.entity";
import {usePawnRepository} from "../../../../../../domain/repository/pawn.repository";
import {AttendancePawnFormComponent} from "../attendance-pawn/attendance-pawn-form.component";
import {AttendancePawnList} from "../attendance-pawn/attendance-pawn.list";
import {Button, List, Modal} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {JbDropzoneComponent} from "../../../../core/dropzone";

// @ts-ignore
export function AttendancePawnTab(props: {
  attendance: AttendanceEntity
}) {
  const [items, setItems] = useState<PawnEntity[]>([]);
  const [createItem, setCreateItem] = useState(false);
  const [editItem, setEditItem] = useState<PawnEntity>();
  const [showAttachments, onShowAttachments] = useState<PawnEntity>();
  const pawnRepo = usePawnRepository();
  
  const listPawns = useCallback(() => {
    pawnRepo.list().then(res => setItems(res.data));
  }, [])
  
  const onRemove = useCallback((id: string) => {
    pawnRepo.remove(id).then(() => listPawns())
  }, [listPawns])
  
  const submitPawn = useCallback((values: any) => {
    pawnRepo.create({ attendanceId: props.attendance.id, ...values}).then(() => {
      listPawns()
      setCreateItem(false)
    });
  }, [props.attendance])
  
  const updatePawn = useCallback((values: any, pawnId: string) => {
    pawnRepo.edit(pawnId, {  ...values}).then(() => {
      listPawns()
      setEditItem(undefined)
    });
  }, [props.attendance])
  
  const removeAttachment = useCallback((pawnId: string, attachmentId: string) => {
    pawnRepo.removePawnAttach(pawnId, attachmentId).then(() => {
      onShowAttachments(undefined);
      listPawns()
    })
  }, [onShowAttachments, listPawns])
  
  const onCreateAttachment = useCallback((id: string, file: File) => {
    pawnRepo.uploadAttachment(id, file).then(() => {
      onShowAttachments(undefined);
      listPawns()
    });
  }, [listPawns])
  
  useEffect(() => {
    listPawns()
  }, [listPawns]);
  
  return (
      <div>
        <div style={{width: '100%', textAlign: 'right', marginBottom: 5}}>
          <Button
            onClick={() => setCreateItem(true)}
            type={'ghost'}
            icon={<PlusOutlined />}
            shape={'round'}
          >
            Adicionar Item
          </Button>
        </div>
        <AttendancePawnList
          data={items}
          onRemove={onRemove}
          onEdit={setEditItem}
          onShowAttachments={onShowAttachments}
        />
        
        {
          createItem && (
            <Modal
              width={1024}
              footer={null}
              onCancel={() => setCreateItem(false)}
              open
            >
              <AttendancePawnFormComponent
                onSubmit={submitPawn}
                onCancel={() => setCreateItem(false)}
              />
            </Modal>
          )
        }
        
        {
          editItem && (
            <Modal
              width={1024}
              footer={null}
              onCancel={() => setCreateItem(false)}
              open
            >
              <AttendancePawnFormComponent
                onSubmit={values => updatePawn(values,editItem.id)}
                onCancel={() => setEditItem(undefined)}
                initialValues={editItem}
              />
            </Modal>
          )
        }
        
        {
          showAttachments && (
            <Modal
              open
              footer={null}
              onCancel={() => onShowAttachments(undefined)}
            >
              <JbDropzoneComponent
                onDrop={(file) => onCreateAttachment(showAttachments?.id, file)}
                description={'Anexos'}
              />
              
              <List
                dataSource={showAttachments?.attachments ?? []}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <Button type={'link'} size={'small'} onClick={() => removeAttachment(showAttachments.id, item.id)}>
                        <DeleteOutlined />
                      </Button>
                    ]}
                  >
                    <List.Item.Meta title={item.name} description={item.mimetype} />
                  </List.Item>
                )}
              />
            </Modal>
          )
        }
      </div>
  );
}