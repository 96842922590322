import {Button, Col, Form, Input, Row} from "antd";
import {useEffect} from "react";

export function FormProcessStageComponent(props: {
  initialValues?: any,
  onSubmit: (values: any) => any,
  onCancel: () => any,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (props.initialValues) form.setFieldsValue(props.initialValues);
  }, [form, props.initialValues])
  
  return (
    <Form form={form} layout={'horizontal'} onFinish={props.onSubmit}>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'description'} label={'Descrição'} rules={[{ required: true, }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button type={'primary'} htmlType={'submit'}>Cadastrar</Button>
        </Col>
      </Row>
      
    </Form>
  )
}