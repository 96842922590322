import {ModulePageProps} from "../../../core/module-pages";
import {JbPageHeaderComponent} from "../../../core/component/jb-page-header.component";
import React, {useCallback, useEffect, useState} from "react";
import {useProcessTypeRepository} from "../domain/repository/process-type.repository";
import {FormProcessTypeComponent} from "../component/form.process-type.component";
import {ProcessTypeEntity} from "../domain/entity/process-type.entity";
import {useParams} from "react-router-dom";
import {FormProcessSubtypeComponent} from "../component/form.process-subtype.component";
import {JbTabComponent, JbTabsComponent} from "../../../core/component/jb-tabs.component";
import {ListProcessStageComponent} from "../component/list.process-stage.component";
import {FormProcessStageComponent} from "../component/form.process-stage.component";
import {useProcessStageRepository} from "../../../../../domain/repository/process-stage.repository";

export function EditProcessTypePage(props: ModulePageProps) {
  const [processType, setProcessType] = useState<ProcessTypeEntity>()
  const processTypeRepo = useProcessTypeRepository()
  const processStageRepo = useProcessStageRepository();
  
  const { id: processTypeId } = useParams<any>();

  useEffect(() => {
    findProcessType()
  }, [processTypeId])
  
  const findProcessType = useCallback(() => {
    processTypeRepo.find(processTypeId).then(res => setProcessType(res.data))
  }, [processTypeId])

  const onEdit = useCallback( (values: any) => {
    if (!values.integrationEnabled) {
      values.integrationEnabled = false
    }
    return processTypeRepo.edit(processTypeId, values).then(res => {
      props.navigator('list')
    })
  },[])
  
  const onCreateProcessStage = useCallback((values: any) => {
    processStageRepo.create({...values, processTypeId}).then(
      () => findProcessType()
    )
  }, [processTypeId, findProcessType])
  
  const onRemoveStage = useCallback((id: string) => {
    processStageRepo.remove(id).then(() => findProcessType())
  }, [])

  return (
    <>
      <JbPageHeaderComponent
        title={`Parametrizações / Tipo de Process / ${processType?.name}`}
        description={'Cadastrar Tipo de Processo'}
      />
      <JbTabsComponent>
        <JbTabComponent title={'Dados Básicos'} key={'1'}>
          <FormProcessTypeComponent
            onSubmit={onEdit}
            values={processType}
          />
        </JbTabComponent>
        <JbTabComponent title={'Sub Tipos'} key={'2'} >
          <FormProcessSubtypeComponent
            processTypeId={processTypeId}
          />
        </JbTabComponent>
        <JbTabComponent title={'Etapas'} key={'3'} >
          <FormProcessStageComponent
            onSubmit={onCreateProcessStage}
            onCancel={console.log}
          />
          <ListProcessStageComponent
            data={processType?.stages ?? []}
            onRemove={item => onRemoveStage(item.id)}
          />
        </JbTabComponent>
      </JbTabsComponent>
    </>
  )
}
