import {Descriptions, Divider, Table} from "antd";
import {useDatetimeUtil} from "../../../../../core/util/datetime.util";
import {useMemo} from "react";
import {DatajudProcessEntity, DatajudProcessTaskEntity} from "../../../../../domain/entity/datajud-process.entity";

export function DescribeSearchComponent(props: {  data: DatajudProcessEntity }) {
  const { data } = props;
  const {formDateTimeInPtBr} = useDatetimeUtil()
  
  const tasks = useMemo(() => {
    return data.tasks?.sort((a: DatajudProcessTaskEntity, b: DatajudProcessTaskEntity) => {
      if (new Date(a.createdAt) < new Date(b.createdAt)) return 1;
      if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
      return 0;
    }) ?? []
  }, [data]);
  
  return (
    <>
      <Descriptions title={'Informações Básicaas'} bordered layout={'vertical'}>
        <Descriptions.Item label={'Classe'}>
          {data?.processClass?.id} - {data?.processClass?.name}
        </Descriptions.Item>
        <Descriptions.Item label={'Data Ajuizamento'}>
          {data.startProcessAt && formDateTimeInPtBr(data.startProcessAt)}
        </Descriptions.Item>
        <Descriptions.Item label={'Ultima Atualização'}>
          {formDateTimeInPtBr(data?.lastUpdatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label={'Orgao Julgador'}>
          {data?.judgementCourt?.name}
        </Descriptions.Item>
        <Descriptions.Item label={'Assuntos'}>
          {data?.subjects?.map((w: any) => w.name)?.join(', ')}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Table
        columns={[
          {
            title: 'Movimento',
            dataIndex: 'name',
          },
          {
            title: 'Data Hora',
            dataIndex: 'createdAt',
            render: formDateTimeInPtBr
          }
        ]}
        dataSource={tasks}
      />
    </>
  )
}