import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {Button, Descriptions, Select} from "antd";
import {AttendanceStatusComponent} from "../attendance-status.component";
import React, {useCallback, useMemo, useState} from "react";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";
import {ProcessEntity} from "../../domain/entity/process.entity";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {AttendanceStatusVo} from "../../domain/vo/attendance-status.vo";
import {AttendanceStatusActions} from "../attendance-status-actions";
import {EditOutlined} from "@ant-design/icons";
import {ProcessTypeEntity} from "../../../process-type/domain/entity/process-type.entity";

export function AttendanceDescriptionComponent(props: {
  attendance?: AttendanceEntity,
  process?: ProcessEntity,
  steps?: AttendanceStepEntity[],
  reload: () => any,
}) {
  const [editProcessStage, setEditProcessStage] = useState(false)
  const { attendance, process, steps } = props
  const { formDateInPtBr } = useDatetimeUtil();

  const attendanceRepo = useAttendanceRepository();
  
  function suspendAttendance(suspensionDeadline: Date) {
    attendanceRepo.changeStatus(
      props.attendance?.id as string,
      'STOPPED',
      suspensionDeadline
    ).then(() => props.reload())
  }
  
  function reopenAttendance() {
    attendanceRepo.changeStatus(
      props.attendance?.id as string,
      'OPENED'
    ).then(() => props.reload())
  }
  
  function concludeAttendance() {
    attendanceRepo.changeStatus(
      props.attendance?.id as string,
      "CONCLUDED"
    ).then(() => props.reload())
  }
  
  return (
    <Descriptions
      bordered
      size={'small'}
      layout={'vertical'}
      column={4} >
      <Descriptions.Item label={'Cliente'}>
        {attendance?.client.name}
      </Descriptions.Item>
      <Descriptions.Item label={'Tipo'}>
        {attendance?.attendanceType?.name}
      </Descriptions.Item>
      <Descriptions.Item label={'Abertura'}>
        {formDateInPtBr(attendance?.createdAt ?? new Date())}
      </Descriptions.Item>
      <Descriptions.Item label={'Status Atual'}>
        <AttendanceStatusComponent
          status={attendance?.status ?? ''}
          suspensionDeadline={attendance?.suspensionDeadline}
        />
        <div style={{float: 'right'}}>
          <AttendanceStatusActions
            status={attendance?.status as AttendanceStatusVo}
            onConclude={concludeAttendance}
            onStop={suspendAttendance}
            onOpen={reopenAttendance}
          />
        </div>
      </Descriptions.Item>
      <Descriptions.Item label={'Tipo Processo'}>
        {process?.processType.name ?? <NotInformed />}
      </Descriptions.Item>
      <Descriptions.Item label={'Sub Tipo'}>
        {process?.processSubtype?.description ?? <NotInformed />}
      </Descriptions.Item>
      <Descriptions.Item label={'Código do Processo'}>
        {process?.code ?? <NotInformed />}
      </Descriptions.Item>
      <Descriptions.Item label={'Etapa do Processo'}>
        {
          editProcessStage ? (
            <EditProcessStage
              attendanceId={props.attendance?.id ?? ''}
              processType={process?.processType}
              processStageId={props.attendance?.processStage?.id}
              onFinish={() => setEditProcessStage(false)}
            />
          ) : (
            attendance?.processStage?.description ?? <NotInformed />
          )
        }
        
        <Button
          onClick={() => setEditProcessStage(true)}
          icon={<EditOutlined />} size={'small'}
          style={{position: 'absolute', right: 4}}
        >
          Editar
        </Button>
      </Descriptions.Item>
    </Descriptions>
  )
}


function EditProcessStage(props: {
  attendanceId: string,
  processStageId?: string,
  processType?: ProcessTypeEntity,
  onFinish: () => any,
}) {
  const attendanceRepo = useAttendanceRepository();
  
  const changeStage = useCallback((stageId: string) => {
    attendanceRepo.edit(props.attendanceId, { processStageId: stageId }).then(() => props.onFinish())
  }, [props.attendanceId]);
  
  
  return (
    <Select
      placeholder={'Selecione um Valor'}
      size={'small'}
      defaultValue={props.processStageId}
      onChange={value => changeStage(value)}
      options={props.processType?.stages?.map(w => ({
        label: w.description,
        value: w.id,
      })) ?? []}
    />
  )
}

const NotInformed = () => <label style={{fontSize: 'small', color: 'gray'}}>Não Informado</label>