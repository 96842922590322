import React, {useEffect, useMemo, useState} from "react";
import {AttendanceEntity} from "../../domain/entity/attendance.entity";
import {useAttendanceRepository} from "../../domain/repository/attendance.repository";
import {useProcessRepository} from "../../domain/repository/process.repository";
import {ProcessEntity} from "../../domain/entity/process.entity";
import {AttendanceStepEntity} from "../../domain/entity/attendance-step.entity";
import {Card, Col, Divider, Row, Tabs} from "antd";
import {AttendanceDescriptionComponent} from "./attendance-description.component";
import {AttendanceStepTabComponent} from "./attendance-step.tab.component";
import {AttendanceOwnersTabComponent} from "./attendance-owners.tab.component";
import {AttendancePartsTabComponent} from "./attendance-parts.tab.component";
import {AttendanceAuditEvents} from "../attendance-audit-events";
import {ProcessObjectAttachedTabComponent} from "./process-object-attached-tab.component";
import {AttendanceAttachmentsTabComponent} from "./attendance-attachments.tab.component";
import {AttendancePublicInteractionTabComponent} from "./attendance-public-interaction.tab.component";
import {AttendanceDescriptionTab} from "./attendance-description.tab";
import {AttendanceFinanceTabComponent} from "./attendance-finance.tab.component";
import {useCheckPermissionUtl} from "../../../../../../core/util/check-permission.utl";
import {DatajudFollowupComponent} from "./datajud-followup.component";
import {AttendancePawnTab} from "./attendance-pawn.tab";

export function FullAttendanceComponent(props: {
  attendanceId: string
}) {
  const [attendance, setAttendance] = useState<AttendanceEntity>()
  const [process, setProcess] = useState<ProcessEntity>()
  const [steps, setSteps] = useState<AttendanceStepEntity[]>([])
  const [activeTab, setActiveTab] = useState('changes');
  const { hasFinanceEntryPermission } = useCheckPermissionUtl()
  const processRepo = useProcessRepository();
  const attendanceRepo = useAttendanceRepository()
  const { attendanceId } = props;

  function loadAttendance(id: string) {
    return Promise.all([
      attendanceRepo.find(id).then(res => {
        setAttendance(res.data)
        const { processId } = res.data;
        if (processId) {
          processRepo.find(processId)
            .then(resProcess => setProcess(resProcess.data))
        }
      }),
      attendanceRepo.listSteps(attendanceId).then(res => {
        const stepsOrdered = res.data.sort((prev, cur) => {
          if (prev.beginDate > cur.beginDate) {
            return -1
          } else if (prev.beginDate < cur.beginDate) {
            return  1
          }
          return 0
        })
        setSteps(stepsOrdered)
      })
    ])
  }

  useEffect(() => {
    if(attendanceId) {
      loadAttendance(attendanceId)
    }
  }, [attendanceId])
  
  
  const tabs = useMemo(() => {
    const tabs = [
      {
        tabKey: 'changes',
        label: 'Movimentações',
        key: 'changes'
      },
      {
        tabKey: 'description',
        label: 'Relato do Caso',
        key: 'description'
      },
      {
        tabKey: 'process-part',
        label: 'Partes Envolvidas',
        key: 'process-part'
      },
      {
        tabKey: 'attendance-owners',
        label: 'Usuários Responsáveis',
        key: 'attendance-owners'
      },
      {
        tabKey: 'attendance-audit',
        label: 'Histórico',
        key: 'attendance-audit'
      },
      {
        tabKey: 'process-object',
        label: 'Objetos do Process',
        key: 'process-object'
      },
      {
        tabKey: 'attachements',
        label: 'Anexos de Processo',
        key: 'attachments'
      },
      {
        tabKey: 'public-interactions',
        label: 'Interações Públicas',
        key: 'public-interactions'
      },
      {
        tabKey: 'pawn',
        label: 'Bens Penhorados',
        key: 'pawn'
      },
    ];
    
    if (process) {
      tabs.push({
        tabKey: 'process-followup',
        label: 'Andamento Processual',
        key: 'process-followup'
      })
    }
    
    if(hasFinanceEntryPermission()) {
      tabs.push({
        tabKey: 'fianncence-data',
        label: 'Financeiro',
        key: 'finance-data'
      });
    }
    return tabs;
  }, [hasFinanceEntryPermission, process])
  
  return (
    <>
      <Row>
        <Col span={24}>
          <Card headStyle={{textAlign:'center'}} bodyStyle={{padding: 0}}>
            <AttendanceDescriptionComponent
              attendance={attendance}
              steps={steps}
              process={process}
              reload={() => loadAttendance(attendanceId)}
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row gutter={12}>
        <Col span={4}>
          <Tabs
            type={'card'}
            onChange={setActiveTab}
            tabPosition={'left'}
            defaultValue={activeTab}
            items={tabs}
          />
        </Col>
        <Col span={20}>
            {
              activeTab === 'changes' && (
                <AttendanceStepTabComponent
                  steps={steps}
                  attendance={attendance}
                  reloadAttendance={() => loadAttendance(attendanceId)}
                />
              )
            }
            {
              activeTab === 'description' && (
                <AttendanceDescriptionTab
                  attendance={attendance as AttendanceEntity}
                  reloadAttendance={() => loadAttendance(attendanceId)}
                />
              )
            }
            {
              activeTab === 'process-part' && (
                <AttendancePartsTabComponent
                  attendance={attendance}
                  reloadAttendance={() => loadAttendance(attendanceId)}
                />
              )
            }
            {
              activeTab === 'attendance-owners' && (
                <AttendanceOwnersTabComponent
                  attendanceId={attendance?.id ?? ''}
                />
              )
            }
            {
              activeTab === 'attendance-audit' && attendance && (
                <AttendanceAuditEvents
                  attendance={attendance}
                />
              )
            }
            {
              activeTab === 'process-object' && attendance?.processId && attendance && (
                <ProcessObjectAttachedTabComponent
                  processId={attendance.processId}
                />
              )
            }
            {
              activeTab === 'attachments' && attendance && (
                <AttendanceAttachmentsTabComponent
                  attendanceId={attendance.id}
                />
              )
            }
          {
            activeTab === 'public-interactions' && (
              <AttendancePublicInteractionTabComponent
                attendanceId={attendanceId}
              />
            )
          }
          {
            activeTab === 'finance-data' && attendance && (
              <AttendanceFinanceTabComponent
                attendance={attendance}
              />
            )
          }
          {
            activeTab === 'process-followup' && process && (
              <DatajudFollowupComponent
                process={process}
              />
            )
          }
          {
            activeTab === 'pawn' && attendance && (
              <AttendancePawnTab
                attendance={attendance}
              />
            )
          }
        </Col>
      </Row>
    </>
  )
}
