import {useCallback, useMemo} from "react";
import axios from "axios";

export function useJbHttpAddressSearchService() {
  const httpClient = useMemo(() => {
    return axios.create({
      baseURL: 'https://viacep.com.br/ws'
    })
  }, [])
  
  const searchAddressByPostalCode: (postalCode: string) => Promise<AddressResponse> = useCallback((postalCode: string) => {
    return httpClient.get(`${postalCode}/json`).then(res => {
      if (res.statusText !== 'OK') throw Error(`Address not found for ${postalCode}`)
      return {
        postalCode: res.data['cep'],
        street: res.data['logradouro'],
        district: res.data['bairro'],
        city: res.data['localidade'],
        state: res.data['estado'],
      }
    })
  }, [httpClient])
  
  return {
    searchAddressByPostalCode
  }
}

export type AddressResponse = {
  postalCode?: string,
  street?: string,
  district?: string,
  city?: string,
  state: string,
}
