import {Col, Form, Input, InputNumber, Row, Select, Typography} from "antd";
import {JbInputNumberComponent} from "../../../../core/form/jb-input-number.component";
import {JbFormActionButtonComponent} from "../../../../core/component/jb-form-action-button.component";
import {useJbHttpAddressSearchService} from "../../../../../../core/service/jb-http-address-search.service";
import {useCallback, useEffect} from "react";

export function AttendancePawnFormComponent(props: {
  onSubmit: (values: any) => any,
  onCancel: () => any,
  initialValues?: any,
}) {
  const [form] = Form.useForm();
  const {searchAddressByPostalCode} = useJbHttpAddressSearchService();
  
  const searchAddress = useCallback((postaCode: string) => {
    searchAddressByPostalCode(postaCode).then(res => {
      form.setFieldsValue(res);
    })
  }, [searchAddressByPostalCode])
  
  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue(props.initialValues);
    }
  }, [props.initialValues]);
  
  return (
    <Form
      onFinish={props.onSubmit}
      layout={'vertical'}
      form={form}
    >
      <Typography.Title level={5}>Dados do Item</Typography.Title>
      <Row gutter={12}>
        <Col span={16}>
          <Form.Item label={'Descrição da Penhora'} name={'description'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'status'} label={'Status'} rules={[{ required: true}]}>
            <Select
              options={[
                { label: 'Adjudiciado', value: 'Adjudiciado', },
                { label: 'Alienado', value: 'Alienado', },
                { label: 'Liberado', value: 'Liberado', },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name={'value'} label={'Valor de Bem'}>
            <JbInputNumberComponent />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'pawnValue'} label={'Valor da Penhora'}>
            <JbInputNumberComponent />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5}>Localização</Typography.Title>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'postalCode'} label={'CEP'}>
            <Input onBlur={evt => searchAddress(evt.target.value)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'street'} label={'Rua'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'number'} label={'Número'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item name={'district'} label={'Bairro'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'city'} label={'Cidade'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'state'} label={'Estado'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <JbFormActionButtonComponent onCancel={props.onCancel}/>
    </Form>
  )
}