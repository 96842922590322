import {Tag} from "antd";
import {useMemo} from "react";

export function AttendancePawnStatusComponent(props: { status: string }) {
  const color = useMemo(() => {
    switch (props.status) {
      case 'Alienado':
        return 'warning';
      case 'Liberado':
        return 'cyan';
      default:
        return 'default'
    }
  }, [props.status]);
  
  return (
    <Tag color={color}>
      {props.status}
    </Tag>
  )
}