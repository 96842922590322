import {useJbHttpCrudService} from "../../core/service/jb-http-crud.service";
import {PawnEntity} from "../entity/pawn.entity";
import {BaseResponse} from "../../core/common/base-response.common";

export function usePawnRepository() {
  const crud = useJbHttpCrudService<PawnEntity>('pawn');
  
  function uploadAttachment(id: string, file: File) {
    const formData = new FormData()
    formData.set('file', file)
    return crud.put(
      `${crud.resourceName}/${id}/save-attachment`,
      formData
    )
  }
  
  function removePawnAttach(pawnId: string, attachmentId: string): Promise<BaseResponse<PawnEntity>> {
    return crud.delete(
      `${crud.resourceName}/${pawnId}/attachment/${attachmentId}`
    )
  }
  
  return {
    ...crud,
    uploadAttachment,
    removePawnAttach,
  }
}