import {PawnEntity} from "../../../../../../domain/entity/pawn.entity";
import {Badge, Button, Table} from "antd";
import {JbTableRowActionsComponent} from "../../../../core/component/jb-table-row-actions.component";
import {AttendancePawnStatusComponent} from "./attendance-pawn-status.component";
import {FileAddFilled, FileOutlined} from "@ant-design/icons";
import {JbButtonComponent} from "../../../../core/component/jb-button.component";

export function AttendancePawnList(props: {
  data: PawnEntity[],
  onRemove: (itemId: string) => any,
  onEdit: (item: PawnEntity) => any,
  onShowAttachments: (item: PawnEntity) => any,
}) {
  return (
    <Table
      columns={[
        {
          title: 'Descrição',
          dataIndex: 'description'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (status: string) => (
            <AttendancePawnStatusComponent status={status} />
          )
        },
        {
          title: 'Localização',
          render: (row: any) => <ShowLocalization pawn={row} />
        },
        {
          title: '',
          dataIndex: 'id',
          render: (id, row) => (
            <JbTableRowActionsComponent
              onEdit={() => props.onEdit(row)}
              onRemove={() => props.onRemove(id)}
              extra={[
                <Badge size={'small'} count={row?.attachments?.length} >
                  <FileOutlined
                    type={'link'}
                    onClick={() => props.onShowAttachments(row)}
                    title={'Anexos'}
                  />
                </Badge>
              ]}
            />
          )
        }
      ]}
      dataSource={props.data}
    />
  )
}


function ShowLocalization(props: { pawn: PawnEntity }) {
  const { city, state, street, number } = props.pawn
  return <>{street} {number} {city} - {state}</>
}