import {ProcessStageEntity} from "../../../../../domain/entity/process-stage.entity";
import {Button, List} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

export function ListProcessStageComponent(props: {
  data: ProcessStageEntity[],
  onRemove: (item: ProcessStageEntity) => any,
}) {
  return (
    <List
      dataSource={props.data}
      renderItem={item => (
        <List.Item extra={[
          <Button
            size={'small'}
            type={'ghost'}
            icon={<DeleteOutlined />}
            onClick={() => props.onRemove(item)}
          >
            Remover
          </Button>
        ]}>
          <List.Item.Meta
            title={item.description}
          />
        </List.Item>
      )}
    />
  )
}