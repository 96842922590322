import {DescribeSearchComponent} from "../../../search-process/component/describe-search.component";
import {useEffect, useState} from "react";
import {DatajudProcessEntity} from "../../../../../../domain/entity/datajud-process.entity";
import {useJbHttpTjService} from "../../../../../../core/service/jb-http-tj.service";
import {Alert, Empty, Tag} from "antd";
import {ProcessEntity} from "../../domain/entity/process.entity";
import {ClockCircleOutlined} from "@ant-design/icons";
import {useDatetimeUtil} from "../../../../../../core/util/datetime.util";

export function DatajudFollowupComponent(props: {
  process: ProcessEntity,
}) {
  const [data, setData] = useState<DatajudProcessEntity>();
  const {findDatajudProcess} = useJbHttpTjService();
  const {
    code
  } = props.process
  
  useEffect(() => {
    findDatajudProcess(props.process.id).then(res => setData(res.data));
  }, [props.process]);
  
  
  if (!props?.process?.enableFollowup) {
    return <Alert description={'O acompanhamento processual está desativado para este processo.'} type={'info'} />
  }
  if (!data) {
    return <Empty description={'Não foram encontrados dados relacionados ao código ' + code} />
  }
  return (
    <>
      <Alert
        type={'warning'}
        message={
          <>
            O andamento processual está usando a base do DATRAJUD para acompanhar os andamentos do processo.
            <a href={'https://datajud-wiki.cnj.jus.br/'} target={'_blank'}> clique aqui</a> para mais informação
          </>
        }
      />
      <br />
      { data?.lastVerification && <TagLastVerification date={data?.lastVerification} /> }
      <DescribeSearchComponent data={data} />
    </>
    
  )
}

export function TagLastVerification(props: { date: Date | string }) {
  const { formDateTimeInPtBr } = useDatetimeUtil();
  return (
    <div style={{textAlign: "right"}}>
      <Tag
        icon={<ClockCircleOutlined />}
      > Última Verificação
        {formDateTimeInPtBr(props.date)}
      </Tag>
    </div>
  )
}